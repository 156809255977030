import React,{useState, useEffect} from 'react'
import axios from 'axios'
import Footer from './Footer'
import Header from './Header'
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom"
import Marquee from "react-fast-marquee"

const CabinetMinisters = ()=>{

    const [data, setData] = useState('')

    const getData =()=>{
        axios
        .get(
        `https://cms.iansnews.in/api/misc/?tags=battleground-2024` 
        ).then((res) => {
        
            if(res.data.results &&
            res.data.results.length > 0 && res.data.results[0].description){
                setData(res.data.results[0].description);  
            } 
        });
    } 

  const [partyCountD, setPartyCountD] = useState()
    useEffect(() => {
      getData()
   
    }, [])
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header/>

      
    
    <main id="main">
        <div className="container poll-page">
        <section id="about" className="about">
         
          <div className="row">
              {/* <h2 className="m-hed">assembly election results 2019 </h2> */}
              <div className="points_table" dangerouslySetInnerHTML={{ __html:  data ? data : '' }} ></div>
            
            



       
        </div>		
      </section>
        </div>
    </main>               
    <Footer />  
</>
)
}
export default CabinetMinisters