import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { useLocation, Redirect } from 'react-router-dom';

const Login = ()=>{
    const [collectionData, setCollectionData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const[inputData, setInputData] = useState({
        username:'',
        password:''
    })
    const userArray = ["july24","aug24","sept24"];
    const ipAdArry = ["182.73.225.60"];
    const history = useHistory();
    const location = useLocation();
    const [checkUserStatus,  setCheckUserStatus] = useState(false)
    const [checkIpStatus, setCheckIpStatus] = useState(false)
    const [currIp, setCurrIp] = useState('')

    const { from } = location.state || { from: { pathname: '/' } };

      const handleOnChange = event => {
        const { name, value } = event.target;
        setInputData({ ...inputData, [name]: value });
      };
        let engStatus = false;
        let hindiStatus = false;
        let photoStatus = false;
        let videoEngStatus = false;
        let videoHindiStatus = false;
const checkLoginValid = (user)=>{
    if(!user){
        return false;
    }
    setCheckUserStatus(false)
    setCheckIpStatus(false)
    const filteredUser= userArray.filter(item => item === user);
    //.log("ffffrrr", filteredUser.length);
    if(filteredUser && filteredUser.length > 0){
        setCheckUserStatus(true)
       
        axios.get(`https://api.ipify.org/?format=json`)
        .then(response => {
        if(response && response.data && response.data.ip){

            setCurrIp(response.data.ip)
            const filteredIp= ipAdArry.filter(iptem => iptem === response.data.ip);
            if(filteredIp && filteredIp > 0){
                setCheckIpStatus(true)
            }
        }
        }).catch(err =>{
            console.log(err,'eeeee');
            setErrorMsg(err);
        })  
        if(!checkUserStatus || !checkIpStatus){
            return false
        }
        //const filteredIp= ipAdArry.filter(iptem => iptem === user);    
        //return true;
    }



}
        const loginUser = (e) => {
// console.log("useee",userArray);
// console.log("ipp",ipAdArry);

            e.preventDefault()
            if(!inputData.username){
                setErrorMsg('Email can not be blank');
            }else if(!inputData.password){
                setErrorMsg('Password can not be blank');

            }else{
                // const validLogin = checkLoginValid(inputData.username)
                // if(!validLogin){
                //     setErrorMsg("You have no permission for login.");
                //     return false
                // }
                let formData = new FormData();

                formData.append('username', inputData.username);
                formData.append('password', inputData.password);
                axios.post(`https://cms.iansnews.in/api/login/`,inputData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                if(response && response.data && response.data.status){
                    localStorage.setItem('accessToken', response.data.token? response.data.token :'');
                    localStorage.setItem('userName', response.data.name? response.data.name:'');
                    localStorage.setItem('user_name', response.data.username? response.data.username:'');
                    localStorage.setItem('expiry_time',new Date().getTime() + 3 * 24 * 60 * 60 * 1000); // 3 days in milliseconds);
                    response.data.groups && response.data.groups.length > 0 && response.data.groups.forEach(group => {
                        if (group === "IANS English") {
                          engStatus = true;
                        } else if (group === "IANS Hindi") {
                          hindiStatus = true;
                        } else if (group === "IANS Photo") {
                          photoStatus = true;
                        } else if (group === "IANS Video English") {
                          videoEngStatus = true;
                        } else if (group === "IANS Video Hindi") {
                          videoHindiStatus = true;
                        }
                      }); 
                      localStorage.setItem('engStatus', engStatus);
                      localStorage.setItem('hindiStatus', hindiStatus);
                      localStorage.setItem('photoStatus', photoStatus);
                      localStorage.setItem('videoEngStatus', videoEngStatus);
                      localStorage.setItem('videoHindiStatus', videoHindiStatus);
                                          
                    // if(localStorage && localStorage.redirectTo){
                    //     history.push(localStorage.redirectTo);
                    // }else{
                    //     history.push('/');
                    // }
                    if(localStorage && localStorage.redirectTo && localStorage.langee == 'english'){
                        window.location.href = '/english-wire-detail'+localStorage.redirectTo;
                    }else if(localStorage && localStorage.redirectTo && localStorage.langee == 'hindi'){
                        window.location.href = '/hindi-wire-detail'+localStorage.redirectTo;     
                    }else if(localStorage && localStorage.redirectTo && localStorage.langee == 'photo'){
                        window.location.href = '/photo-wire-detail'+localStorage.redirectTo;     
                    }else if(localStorage && localStorage.redirectTo && localStorage.langee == 'video'){
                        window.location.href = '/video-wire-detail'+localStorage.redirectTo;                                                                      
                        //history.push('/detail'+localStorage.redirectTo);
                    // window.reload()
                    }else{
                        history.push('/english-wire/all/1');
                    }
                }
                console.log(response, 'rrrrrrrrrrr');
                            setErrorMsg(response.data.message);

                }).catch(err =>{
                    console.log(err,'eeeee');
                    setErrorMsg(err.response.data.message);
                })            

            }

        // console.log(conFom)
      }
      useEffect(() => {
        if(localStorage && localStorage.accessToken){
            history.push('/');
        }
        <Redirect to={from} />
      }, []);
    return(
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service</title>
            <meta data-meta-title="IANS IN" name="keywords" content='' />
            <meta data-meta-title="IANS IN" name="description" content='' />
            <meta property="og:title" content='' />
            <meta property="og:description" content='' />
            <meta property="og:image" content='' />
            <meta property="og:url" content={'https://ians.in/detail'} />
            <meta property="og:type" content="article" />
        </Helmet>
         <Header showType={true} login={true}/>
         <main id="main">
            <section id="contact" className="contact">
                <div className="container">
                    <div className="row mt-5 login-control">
                    <div className="heading">Sign In.</div>
                         {  
                        errorMsg &&
                        <span className="login-error-msg">
                        <i className="bi bi-emoji-angry"></i> <br/>

                        {errorMsg}
                        </span>
                         }
                        <form onSubmit={loginUser}>   
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Username</label>
                            <input type="text" className="form-control" name="username" onChange={handleOnChange} value={inputData.username} size="20"/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                            <input type="password"  className="form-control" name="password" onChange={handleOnChange} value={inputData.email} size="20"/>

                        </div>
                            <button type="submit" className="btn btn-primary">Log In</button>
                        </form>
                        <div className="login-form">
                            <ul>
                            <li>
                                <i className="bi bi-map-marker" aria-hidden="true"></i>IANS India Private Limited, A-6, Ground Floor, Block A, Sector-16, Noida, Uttar Pradesh - 201301
                            </li>
                            <li>
                                <i className="bi bi-phone icon"></i> +91-120-4822400, 4822415, 4822416, <i className="bi bi-mobile" aria-hidden="true"></i>+91-9650730303
                            </li>
                            <li>
                                <i className="bi bi-envelope" aria-hidden="true"></i>
                                <a href="mailto: dakul.s@ians.in"> dakul.s@ians.in,</a>
                                <a href="mailto: marketing@ians.in">marketing@ians.in</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
         </main>       
         <Footer />
        </>
    )
}
export default Login