import {NavLink} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import StickySidebar from 'sticky-sidebar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import Calendar CSS

const VideoCatList=(props)=>{

    // const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
      const isFutureDate = (date) => {
        const today = new Date();
        return date > today;
      };
    
    useEffect(() => {
        const sidebar = new StickySidebar('.sticky-sidebar', {
          topSpacing: 0, // Adjust as needed
          bottomSpacing: 0, // Adjust as needed
        });
    
        return () => {
          sidebar.destroy();
        };
      }, []);
    return(
        <>
            <div className="col-lg-3 vid-right-sec sticky-sidebar">
                                <div className="filters">
                                {!props.searchDateVal && !props.langg  &&
                                <span><i className="bi bi-filter"></i> Filters</span>
                                }
                                 {(props.searchDateVal || props.langg) &&
                                    <span onClick={props.clearFilter}><i className="bi bi-filter-circle-fill"></i> Clear Filters</span>
                                 }
                                </div>
                                <div className="dropdown language-dropdown" style={{ marginTop: '15px'}}>
                                    <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Language | {props.langg ?props.langg.toUpperCase() : ''}
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={()=>{
                                            props.setLanguagge('')
                                        }}><NavLink to={`#`}>All</NavLink></li>                                                
                                        <li onClick={()=>{
                                            props.setLanguagge('english')
                                        }}><NavLink to={`#`}>English</NavLink></li>
                                        <li onClick={()=>{
                                            props.setLanguagge('hindi')
                                        }}><NavLink to={`#`}>Hindi</NavLink></li>
                                    </ul>
                                </div>                            
                            <div className="main-heading datefilters">
                                <h2 className="heading">Date - {props.searchDateVal ? props.searchDateVal :''}</h2>
                            </div>
                            <div className="app-date">
                            {/* <Calendar onChange={props.onChange} value={props.selectedDate} /> */}
                            <Calendar
                           onChange={props.onChange}
                           value={props.selectedDate}
                            tileDisabled={({ activeStartDate, date, view }) =>
                            view === 'month' && isFutureDate(date)
                            }
                        />
                            </div>
                            <ul className="nav flex-column sidebar">
                                <div className="main-heading">
                                    <h2 className="heading">Choose Category</h2>
                                </div>
                                {props.categoryList && props.categoryList.length > 0 && props.categoryList.map((tempCatArry, tc)=>{
                                    return(
                                        <>
                                        <li className="nav-item" key={tc}>
                                            <NavLink className="nav-link" aria-current="page" to={`/video-wire/${tempCatArry.slug}/1`}>{tempCatArry.name}</NavLink>
                                        </li>
                                    </>
                                    )
                                })}
                            </ul>
            </div>
        </>
    )
}
export default VideoCatList;