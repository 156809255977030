import {NavLink} from 'react-router-dom';
import React, { useEffect } from 'react';
//import StickySidebar from 'sticky-sidebar';
const Latest=(props)=>{
    // const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
    useEffect(() => {
        // const sidebar = new StickySidebar('.sticky-sidebar', {
        //   topSpacing: 0, // Adjust as needed
        //   bottomSpacing: 0, // Adjust as needed
        // });
    
        return () => {
          //sidebar.destroy();
        };
      }, []);
    return(
        <>
    
            <div className="col-lg-3 col-md-12 sticky-sidebar order-d-2 pr-news-border-left-sec" >
            <div>

                <div className="icon-box">
                <div className="main-heading">
                        <h2 className="heading">Latest News</h2>
                    </div>
                    <div className="container-fluid">
                        <div className="row g-0">
                        
                        <ul className="list-unstyright">
                        {props && props.data && props.data.length > 0 && props.data.slice(0,7).map((lat, l)=>{
                                return(
                                    <li key={l}>
                                    <figure>
                                        <figcaption className="tabtextarea-unstyright">
                                       
                                        <NavLink to={lat.slug} title={lat.title} alt="#"><h3 className="title">{lat.title}</h3></NavLink>
                                       
                                        </figcaption>
                                        <div className="img-area-unstyright">
                                     
                                         <NavLink to={lat.slug}><img src={lat.image ? lat.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg'} title={lat.title} alt="#"/></NavLink>
                                                                              
                                         </div>
                                    </figure>
                                </li>                                    
                                )
                            })}


                        </ul>
                        {/* <IansTweet /> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* )}
            </Sticky>
            </StickyContainer>         */}
        </>
    )
}
export default Latest;