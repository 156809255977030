import Footer from './Footer'
import Header from './Header'
import React from 'react';
import {Helmet} from "react-helmet";
const Contact = ()=>{
    return (
        <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Indo-Asian News Service </title>
            <meta data-meta-title="IANS IN" name="keywords"  />
            <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header />
        <main id="main">
            <section id="contact" className="contact">
                <div className="container contact-us">
                    <div className="row">
                        <div className="main-heading">
                            <h2 className="heading">Contact Us</h2>
                        </div>
                        <div className="col-lg-4">
                            <div className="info">
                                <div className="address"> 
                                    <i className="bi bi-geo-alt"></i>
                                    <h4>Location:</h4>
                                    <p> IANS India Private Limited, A-6, Ground Floor, Block A, Sector-16, Noida, Uttar Pradesh - 201301</p>
                                </div>
                                <div className="email"> <i className="bi bi-envelope"></i><h4>Email:</h4><p><a href="mailto: dakul.s@ians.in">dakul.s@ians.in,</a>  <a href="mailto: marketing@ians.in">marketing@ians.in</a></p></div>
                                <div className="phone"> <i className="bi bi-phone"></i><h4>Call:</h4><p>+91-120-4822400, 4822415, 4822416,</p></div>
                            </div>
                        </div>
                        <div className="col-lg-8 mt-5 mt-lg-0">
                             <div className="map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16642.58241698617!2d77.315563!3d28.5787231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51bb0316b97e197c!2sIANS%20India%20Pvt%20Ltd!5e1!3m2!1sen!2sin!4v1636457200787!5m2!1sen!2sin" width="100%" height="400" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                
           
            </section>
        </main>
        <Footer />
        </>
    )
}
export default Contact