import Footer from './Footer'
import Header from './Header'
import React from 'react';

import {Helmet} from "react-helmet";
const Career = ()=>{
    return (
        <>
        <Helmet>
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
			<title>Indo-Asian News Service </title>
			<meta data-meta-title="IANS IN" name="keywords"  />
			<meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
        <Header />
         <main id="main">
			<section className="inner-page">
			<div className="container">
				<div className="news-cat">
					<h2>Career</h2>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Sales &amp; Marketing Department</h3>
						</div>
						<div className="car-desc">
							<ul>
								<li>1) Business Development &amp; Alliance with 3-5 years experience</li> 
							</ul>
							<p>PREFERENCE WILL BE GIVEN WHO HAVE AN EXPERIENCE IN MEDIA HOUSE.</p>
							<p>Send in your resumes to <a href="mailto:careers@ians.in">careers@ians.in</a></p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Hindi Desk</h3>
						</div>
						<div className="car-desc">
							<ul>
								<li>Senior Sub Editor (sports)</li>
								<li>Sub Editor (sports)</li>
								<li>Sub Editor (General Desk)</li>
								<li>Senior Sub Editor (Business)</li>
								<li>Sub Editor (General Desk)</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Supreme Court Reporter</h3>
						</div>
						<div className="car-desc">
							<p>Fresh Graduates (preferably Law Graduates), or Graduates with 3 to 4 years of experience in Legal reporting may apply.</p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Telecaller at Noida in Collections Team with 0-1 year of experience.</h3>
						</div>
						<div className="car-desc">
							<p><strong>Job Description:&nbsp;</strong></p>
							<p>Telecalling to the Clients for collection.&nbsp;<br />
							Operational work like preparing agreement, MIS reports etc.<br />
							Various other&nbsp;miscellaneous&nbsp;work as and when required.</p>
							<p><strong>Eligibility</strong></p>
							<ul>
								<li>Graduate with 0-1 year of experience</li>
								<li>Excellent Interpersonal, Relationship Building, Presentation &amp; Communication Skills (Written &amp; Verbal).</li>
								<li>Should possess sound Analytical, Decision Making and Problem Solving Skills.-Proficiency in MS Office,&nbsp;Excel, PowerPoint and Word preferred.</li>
								<li>Self-motivating skills.</li>
								<li>Demonstrated ability to work effectively within a team environment as well as independently.</li>
								<li>Ability to deliver result &amp; display Personal Accountability</li>
								<li>Analyze risk on customer accounts, making sound decisions to ensure recovery while maintaining high customer satisfaction levels&nbsp;</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Sub-Editor</h3>
						</div>
						<div className="car-desc">
							<p><strong> Must have:</strong></p>
							<ul>
								<li> Command over English language </li>
								<li> Understanding of news value </li>
								<li> Knowledge of Online media and Social media </li>
								<li> Ability to work in a team </li>
								<li> Knowledge of current affairs/politics/sports/entertainment </li>
								<li> Flexibility to work in shifts </li>
								<li> Degree in Mass Communication</li>
							</ul>
							<p><strong>Job Description:&nbsp;</strong></p>
							<p>Candidate will be required to update website, write photo captions and photo stories.</p>
							<p>Send in your resumes to <a href="mailto:careers@ians.in">careers@ians.in</a></p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Business Department</h3>
						</div>
						<div className="car-desc">
							<p>1) Senior Correspondent - Business with 3-5 years experience.</p>
							<p>2) Correspondent - Business with 1 year experience</p>
							<p><strong>PREFERENCE WILL BE GIVEN WHO HAVE AN EXPERIENCE IN CAPITAL MARKETS, SEBI, MONEY MARKETS ETC.</strong></p>
							<p>Send in your resumes to <a href="mailto:careers@ians.in">careers@ians.in</a></p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Video Producer &amp; Video Editor</h3>
						</div>
						<div className="car-desc">
							<p><strong> Must have:</strong></p>
							<ul>
								<li>At least 6 months internship in video editing</li>
								<li>Basic knowledge of English and Hindi languages</li>
								<li>Working knowledge of Final Cut-Pro, Photoshop</li>
								<li>Flexibility to work in shifts</li>
							</ul>
							<p><strong>Job Description:&nbsp;</strong></p>
							<p>Candidate will be required to edit entertainment, political, sports and tech videos.</p>
							<p>Send in your resumes to <a href="mailto:careers@ians.in">careers@ians.in</a></p>
						</div>
					</div>
				</div>
				<div className="col-sm-12 no-padding">
					<div className="car-full">
						<div className="car-head">
							<h3 className="car-title">Other Positions</h3>
						</div>
						<div className="car-desc">
							<ul><strong>
								<li> Assistant - HR &amp; Admin. (Freshers can also apply) </li>
								<li> Mid and Senior level Reporters (Business) </li>
								<li> Mid and Senior level Desk Hands</li>
								<li> Mid and Senior level Reporters</li>
								<li> Data Entry Operators (Freshers can also apply)</li>
								<li> Assistant – Sales &amp; Marketing (Freshers can also apply)</li>
							</strong></ul>
							<p>Send in your resumes to <a href="mailto:careers@ians.in">careers@ians.in</a></p>
							<p>We will also consider your Profile for future Jobs.</p>
						</div>
					</div>
				</div>
				
			</div>

			</section>
  		 </main>
        <Footer />
        </>
    )
}
export default Career