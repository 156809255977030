import React from 'react'
import {NavLink} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

const Footer=()=>{
  const year = new Date().getFullYear();

    return (
        <>
<ScrollToTop />
  <footer id="footer">
    <div className="footer-top">

      <div className="col-lg-12 col-md-12 footer-partner">
          <ul className="list-unstyled">
            <li>
              <a href="/pr-news-wire/1">PR Newswire</a> 
              {/* <a href="/pr-news-wire/1">पीआर न्यूजवायर</a> */}
            </li>
            <li>
              <a href="/business-wire/1">Business Wire India</a> 
              {/* <a href="/business-wire">बिजनेस वायर इंडिया</a> */}
            </li>
            <li><a href="/vmpl-news/1">NewsReach</a></li>
          </ul>
        </div>
      <div className="container">
        
        <div className="row">

          {/* <div className="col-lg-4 col-md-6 footer-info">

			<img src="https://ians.in/assets/img/w-logo.png" alt="" style={{width:'74%'}}/>
          </div> */}

          <div className="col-lg-4 col-md-6 footer-links services-link">
            <h4>Services</h4>
            <ul>
              <li><a href="/english-wire/all">English News</a></li>
              <li><a href="/hindi-wire/all">Hindi News</a></li>
              <li><a href="/photo-wire/all/1">Picture Service</a></li>
              <li><a href="/video-wire/all/1">Video Service</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/contact-us">Contact Us</a></li>
              <li><a href="/services">Services</a></li>
              <li><a href="/terms-of-use">Terms Of Use</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/technical-support">Technical Support</a></li>
              <li><a href="/career">Careers</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              IANS India Private Limited <br />
               A-6, Ground Floor, Block A<br />
              Sector-16, Noida, Uttar Pradesh <br />
              <strong>Phone:</strong> +91-120-4822400, 4822415, 4822416<br />
              <strong>Email:</strong> dakul.s@ians.in, marketing@ians.in<br />
            </p>
            {/* <div className="social-links">
              <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
              <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
              <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
            </div> */}
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">Copyright © 2024 Indo-Asian News Service (IANS) India Private Limited | Design and Development by IANS Software Services
      </div>
    </div>
  </footer>
  

  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

        </>
    )
}
export default Footer