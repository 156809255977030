import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import {Helmet} from "react-helmet"
import { useHistory } from "react-router-dom"
import AdComponent from './AdComponent'
import { format, parseISO } from 'date-fns'
import AdMultiplex from './AdMultiplex'
import HeadingTab from './HeadingTab'

const PhotoEvent =()=>{
 const history = useHistory();
 const {newsId} = useParams();
 const [collectionData, setCollectionData] = useState({})
 const [login, setLogin] = useState(true)
 const [latData, setLatestData] = useState()
 const [eventData, setEventsData] = useState();

 const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/list/?language=english&page=1`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 6).map((item) => {
          let tgSt= item.tags.filter(it => it.name === 'Business')
 
        return {
            id: item.id,
            image: item.image,
            title: item.title,
            slug:'/event-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
    setLatestData(latestStories)
    })
 }
//  const getEventData =()=>{
//     axios
//     .get(
//     `https://cms.iansnews.in/api/elastic/imagenews/detail/${newsId}/?language=english`
//     )
//     .then((res) => {
//         setCollectionData({
//             dateTime: res.data.results &&
//             res.data.results.length > 0 && 
//             res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
//             title : res.data.results &&
//             res.data.results.length > 0 && 
//             res.data.results[0].title ? res.data.results[0].title: '',
//             tags : res.data.results &&
//             res.data.results.length > 0 && 
//             res.data.results[0].tags ? res.data.results[0].tags: ''
//         });
//     const eventStories =
//         res.data.results &&
//         res.data.results.length > 0 &&
//         res.data.results[0].images_thumbnails.length > 0 &&
//         res.data.results[0].images_thumbnails.map((item) => {
 
//         return {
//             content: item.caption,
//             id: item.id,
//             image: item.image,
//             title: item.title,
//             slug:'/detail/'+item.slug
//         }});
//         setEventsData(eventStories)
//     })
//  }

 const getEventData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/detail/${newsId}/?language=english`
    )
    .then((res) => {
        setCollectionData({
            dateTime: res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
            title : res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].title ? res.data.results[0].title: '',
            tags : res.data.results &&
            res.data.results.length > 0 && 
            res.data.results[0].tags ? res.data.results[0].tags: ''
        });
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results[0].images_thumbnails.length > 0 &&
        res.data.results[0].images_thumbnails.map((item) => {
 
        return {
            content: item.caption,
            id: item.id,
            image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
            title: item.title,
            slug:'/photo-wire-detail/'+item.slug
        }});
        setEventsData(latestStories)
    })
 }
 const formatDate = (timestamp)=>{
    const date = parseISO(timestamp);
    return format(date, 'MMMM dd, yyyy h:mm a');
 }


  useEffect(() => {
    if(!localStorage || !localStorage.accessToken || !localStorage.photoStatus){
        history.push('/login');
    }
    LatestData()
    getEventData()
    
 }, [newsId])
    return(
        <>
    <Header />
        <main id="main">
            <div className="container video-newsec">
                <section id="about" className="about">
                    <HeadingTab />
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="row event-photo">
                                <div className="video-tab-sec">
                                    <h2 className="title">{collectionData.title}</h2>
                                </div>
                                <div className="cat-date-wrap-1">
                                    {collectionData && collectionData.tags && collectionData.tags.length > 0 && collectionData.tags.map((tgg,t)=>{
                                    return(
                                    <span className="cd-cat-M"><a href="#"  key={t}> {tgg.name} </a></span>
                                    )
                                    })}
                                    <span className="cd-date-M">{collectionData.dateTime}</span>
                                </div>
                               
                            {eventData && eventData.length > 0 &&  eventData.map((imgArrry, i)=>{
                                    return(
                                    <div className="col-xl-3 col-lg-4 col-md-6 mb-3 sec-left img-col4">
                                        <NavLink to={imgArrry.slug} title={imgArrry.title}>
                                        <div className="thumb w-100  album-thumb">
                                            <img className="thumb-image w-100" src={imgArrry.image}/>
                                            {/* <div className="text-l-gray des-title mt-3"> {imgArrry.title}</div> */}
                                            <div className="dec-img">
                                                <span className="des-pic-title">{imgArrry.title}</span>
                                            </div>
                                            
                                        </div>
                                        </NavLink>
                                    </div>
                            )})} 
                            </div>
                        </div>
                        {/* <div className="col-lg-3 latest-photo-sec right-sec">
                            <div className="main-heading">
                                <h2 className="heading">LATEST EVENT</h2>
                            </div>
                            <ul className="list-unstyright">
                            {latData && latData.length > 0 && latData.map((tempLD, d)=>{
                                return(
                                    <li key={d}>
                                    <figure>
                                        <figcaption className="tabtextarea-unstyright">
                                            <NavLink title={tempLD.title} alt={tempLD.title} to={tempLD.slug}>
                                                <h3 className="title">{tempLD.title}</h3>
                                            </NavLink>
                                        </figcaption>
                                        <div className="img-area-unstyright">
                                            <NavLink to={tempLD.slug} title={tempLD.title}>
                                                <img src={tempLD.image} title={tempLD.title} alt={tempLD.title}/>
                                            </NavLink>
                                            <div className="img-box-wrap">
                                                <i className="bi bi-camera" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </figure>
                                </li>
                                )
                            })}


                            </ul>
                        </div> */}
                    </div>
                </section>
            </div>
        </main>
    <Footer />
        </>
    )
}
export default PhotoEvent