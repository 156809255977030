const Clar = (props)=>{
    return(
        <>

    { props && props.data && props.data == 'english' && 
          <blockquote>
                For clarifications/queries, please contact IANS NEWS DESK at:
                <span className="quoter">
                    <ul>
                    <li><i aria-hidden="true" className="fa fa-phone"></i> +91-120-4822400</li>
                    <li><i aria-hidden="true" className="fa fa-mobile"></i> +91-9311830312</li>
                    <li><i aria-hidden="true" className="fa fa-envelope"></i> support@ians.in</li>
                    </ul>
                </span>
            </blockquote> 
             }  
        { props && props.data && props.data == 'hindi' && 
          <blockquote>
                स्पष्टीकरण/प्रश्नों के लिए, कृपया आईएएनएस न्यूज डेस्क से संपर्क करें:
            <span className="quoter">
                <ul>
                <li><i aria-hidden="true" className="fa fa-phone"></i> +91-120-4822400</li>
                <li><i aria-hidden="true" className="fa fa-mobile"></i> +91-9311830312</li>
                <li><i aria-hidden="true" className="fa fa-envelope"></i> support@ians.in</li>
                </ul>
            </span>
        </blockquote>
             }                   
        </>
    )
}

export default Clar