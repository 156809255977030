import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Marquee from "react-fast-marquee"
import {NavLink} from 'react-router-dom'
import { useHistory } from "react-router-dom"

import jsonData from './Data/LS_Winner_INDIA2.json'

const ElectionHome = (props) => {
  const [poolData, setPoolDdata] = useState('')
  const [ndaCount, setNdaCount] = useState(0)
  const [indiaCount, setIndiaCount] = useState(0)
  const [dataH, setHData] = useState()
  const SepcData2 =()=>{
      axios
      .get(
      `https://cms.iansnews.in/api/misc/?tags=politics` 
      ).then((res) => {
      
          if(res.data.results &&
          res.data.results.length > 0 && res.data.results[0].description){
              setPoolDdata(res.data.results[0].description);
              setNdaCount(res.data.results[0].title)
              setIndiaCount(res.data.results[0].language)

          } 
      });
  } 
  const getUniqueStates = (data) => {
    const seen = new Set();
        return data.reduce((acc, item) => {
            if (!seen.has(item.s)) {
            seen.add(item.s);
            acc.push(item.s);
            }
            return acc;
        }, []);
}   
const getUniqueParties = (data) => {
    const seen = new Set();
        return data.reduce((acc, item) => {
            if (!seen.has(item.lp)) {
            seen.add(item.lp);
            acc.push(item.lp);
            }
            return acc;
        }, []);
}  
const getUniquePartiesWithCounts = (data) => {
    const partyCounts = {};
    data.forEach((item) => {
        const partyName = item.lp;
        partyCounts[partyName] = (partyCounts[partyName] || 0) + 1;
    });
    return partyCounts;
};
const getJsonHData = ()=>{
  let timestamp = new Date().getTime();
  let temmArry = ""
  const url = `https://ians.in/election_2024.json?t=${timestamp}`;
  // Fetch the JSON file using axios
  axios
    .get(url)
    .then((response) => {
      if(response && response.data){
        setHData(response.data);
        if( response.data &&  response.data.india &&  response.data.india.cs){
          temmArry = convertObjectToArray( response.data.india.cs)
        }
        if(temmArry){
          const listTempData = temmArry.filter(item => targetConstituencies.includes(item.id))
  
          console.log('flttt55',listTempData)
          setListData(listTempData)
          setAssemData(temmArry)
          setConData(temmArry)
          getStateList(temmArry)
          getPartyList(temmArry)
          setAllData(temmArry)
        }
      }
      
      console.log(response.data, "gwalior_----");
    })
    .catch((error) => {
      //setError(error);
      console.error('Error fetching the JSON file:', error);
    });

  /// for local code
      // if(jsonData){
      //   setHData(jsonData);
      //   if(jsonData && jsonData.india &&  jsonData.india.cs){
      //     temmArry = convertObjectToArray(jsonData.india.cs)
      //   }
      //   if(temmArry){
      //     const listTempData = temmArry.filter(item => targetConstituencies.includes(item.id))
  
      //     console.log('flttt55',listTempData)
      //     setListData(listTempData)
      //     setAssemData(temmArry)
      //     setConData(temmArry)
      //     getStateList(temmArry)
      //     getPartyList(temmArry)
      //     setAllData(temmArry)
      //   }
      // }
  
}
const [state, setStateList] = useState()
const [party, setPartyList] = useState()

const [assemData, setAssemData] = useState()
const [conData, setConData] = useState()
const history = useHistory()
const [allData, setAllData] = useState()
const [selectedValue, setSelectedValue] = useState('')
const [selectedPartyValue, setSelectedPartyValue] = useState('');
const [selectedConsValue, setSelectedConsValue] = useState('');
const [partyCountD, setPartyCountD] = useState()
const handleSelectChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value in state
    setSelectedConsValue('')
    setSelectedPartyValue('')
  }
  const handleSelectPartyChange = (event) => {
    setSelectedPartyValue(event.target.value); // Update the selected value in state
  }  
  const handleSelectConsChange = (event) => {
    setSelectedConsValue(event.target.value); // Update the selected value in state
  }  
const getStateList =(data)=>{
    if(data ){
       const uniqueStates = getUniqueStates(data)
       const sortedItems = [...uniqueStates].sort((a, b) => a.localeCompare(b));
       setStateList(sortedItems)
    }
}
const getPartyList=(data)=>{
    const uniqueParties = getUniqueParties(data)
    const sortedttItems = [...uniqueParties].sort((a, b) => a.localeCompare(b));
    setPartyList(sortedttItems)
    const partyCounts = getUniquePartiesWithCounts(data)
    setPartyCountD(partyCounts)
console.log(partyCounts)
}   
const convertObjectToArray = (obj) => {
    return Object.values(obj)
  }
 
const clearAllFun = ()=>{
  let arrayTemp = "";
    setSelectedConsValue('')
    setSelectedValue('')
    setSelectedPartyValue('')
    if(dataH && dataH.india && dataH.india.cs){
       arrayTemp = convertObjectToArray(dataH.india.cs)
    }
    if(arrayTemp){
      setAssemData(arrayTemp)
      setConData(arrayTemp)
      getStateList(arrayTemp)
      getPartyList(arrayTemp)
      setAllData(arrayTemp)
    }
}
  const [electBtn, setElectBtn] = useState(true)
  const [tab,setTab] = useState('home')
  const handleClick =(tb)=>{
    setTab(tb)
    //alert(tb)
  }
  const EleCloseBtn =()=>{
      setElectBtn(!electBtn)
  }
  const MINUTE_MS = 30000;
const [listData, setListData] = useState()
const targetConstituencies = [
  "24077", "24036", "11004", "24037", "11020", "30004", "50006", "20003", "12016", "13010",
  "10014", "06006", "54009", "24042", "13035", "18017", "20017", "04031", "08002", "12004",
  "24005", "30002", "04012", "52002", "13044", "27001", "07002", "09003", "03014", "25018",
  "22020", "02001", "25010", "09001", "19011", "04024", "25021", "08003", "10016", "13026",
];
const ElectionGo=()=>{
  history.push('/lok-sbabha-election-results-2024');
}
  useEffect(() => {
    
    if (selectedValue) {
      const filteredData = allData.filter(item => item.s === selectedValue);
      // filteredData.sort((a, b) => a.s.localeCompare(b.s));
      setAssemData(filteredData)
      //const listData = allData.filter(item => item.s === selectedValue);
      const listTempData = allData.filter(item => targetConstituencies.includes(item.id));

      console.log('flttt',listTempData);
      setListData(listTempData)
      setConData(filteredData)
      //setSelectedConsValue('')
      
    } else {
      getJsonHData()
      // If no state is selected, show all data
      let array = "";
      if(dataH && dataH.india && dataH.india.cs){
        array = convertObjectToArray(dataH.india.cs)
      }
      if(array){
        const listTempData = array.filter(item => targetConstituencies.includes(item.id))

        console.log('flttt55',listTempData)
        setListData(listTempData)
        setAssemData(array)
        setConData(array)
        getStateList(array)
        getPartyList(array)
        setAllData(array)
      }
    }
    SepcData2()

    if(selectedPartyValue){
      const filteredprtData = allData.filter(item => item.lp === selectedPartyValue);

      setAssemData(filteredprtData)        
    }
    if(selectedConsValue){
      const filteredConsData = allData.filter(item => item.n === selectedConsValue);
      // filteredData.sort((a, b) => a.s.localeCompare(b.s));
      setAssemData(filteredConsData)        
    }
   
    const interval = setInterval(() => {
       
        SepcData2()
        getJsonHData()
      }, MINUTE_MS);
    
      return () => clearInterval(interval)
 
  }, [selectedValue, selectedConsValue, selectedPartyValue])


  return (
    <>

      <section id="about" className="about">
        <div className="container poll-container">
        {electBtn &&  
          <div className="row">
          <button type="button" className="btn-close election-close-btn" aria-label="Close" onClick={EleCloseBtn}></button>
          <button className={tab && tab=='home' ?'tablink active' : 'tablink'} onClick={() => handleClick('home')}>Lok sabha election results 2024</button>
          <button className={tab && tab=='news' ?'tablink active' : 'tablink'} onClick={() => handleClick('news')}>ASSEMBLY ELECTION RESULTS 2024</button>
            { tab && tab=='home' &&    
            <div id="Home" className="tabcontent">
              {/* <h2 className="m-hed">assembly election results 2024 </h2> */}
              <div className="slidepoll poll-d-padding-right">
                <div className="hed"> 
                  <span className="hed-ste">NDA</span> 
                  <span className="hed-tot"> {ndaCount ? ndaCount : '00'}/543</span>
                </div>
                <div className="pool-text">
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/BJP.jpg" />
                    <span className="par">BJP</span>
                    <span className="set">{partyCountD && partyCountD.BJP ? partyCountD.BJP : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/JDU.jpg" />
                    <span className="par">JDU</span>
                    <span className="set">{partyCountD && partyCountD.JDU ? partyCountD.JDU : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/SS.jpg" />
                    <span className="par">SHIV SENA</span>
                    <span className="set">{partyCountD && partyCountD.SS ? partyCountD.SS : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/LJPRV.jpg" />
                    <span className="par">LJP(RV)</span>
                    <span className="set">{partyCountD && partyCountD.LJPRV ? partyCountD.LJPRV : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/AJSU.jpg" />
                    <span className="par">AJSU</span>
                    <span className="set">{partyCountD && partyCountD.AJSU ? partyCountD.AJSU : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/TDP.jpg" />
                    <span className="par">TDP</span>
                    <span className="set">{partyCountD && partyCountD.TDP ? partyCountD.TDP : '00'}</span>
                  </p>
                </div>
              </div>
              
              <div className="slidepoll poll-d-padding-left">
                <div className="hed"> 
                  <span className="hed-ste">I.N.D.I.A.</span> 
                  <span className="hed-tot"> {indiaCount ? indiaCount : '00'}/543</span>
                </div>
                <div className="pool-text">
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/CONG.jpg" />
                    <span className="par">Congress</span>
                    <span className="set">{partyCountD && partyCountD.Cong ? partyCountD.Cong :'00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/RJD.jpg" />
                    <span className="par">RJD</span>
                    <span className="set">{partyCountD && partyCountD.RJD ? partyCountD.RJD : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/SP.jpg" />
                    <span className="par">SP</span>
                    <span className="set">{partyCountD && partyCountD.SP ? partyCountD.SP : '00'}</span>
                  </p>    
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/TMC.jpg" />
                    <span className="par">TMC</span>
                    <span className="set">{partyCountD && partyCountD.TMC ? partyCountD.TMC :'00'}</span>
                  </p>                                
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/AAP.jpg" />
                    <span className="par">AAP</span>
                    <span className="set">{partyCountD && partyCountD.AAP ? partyCountD.AAP : '00'}</span>
                  </p>
                  <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/DMK.jpg" />
                    <span className="par">DMK</span>
                    <span className="set">{partyCountD && partyCountD.DMK ? partyCountD.DMK : '00'}</span>
                  </p>

                  {/* <p>
                    <img src="https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/party_symbol/SSUBT.jpg" />
                    <span className="par">SSUBT</span>
                    <span className="set">{partyCountD && partyCountD.SSUBT ? partyCountD.SSUBT : '00'}</span>
                  </p> */}

                </div>
                
              </div>


              <div className="row">
              { listData && listData.length > 0 && 
                <Marquee
                        className="news-scroll"
                        pauseOnHover={true}
                        speed={70}
                        loop={100}
                      >
                        {listData && listData.length > 0 && listData.map((llll, l)=>{
                          return(
                            <>
                          {   llll.c && llll.c.length > 0 &&
                          <div className="column ls-poll-card" key={l}>
                          <div className="card">
                            <h3>{llll.n ? llll.n : ''}</h3>
                            <ul>
                              <li><img src={`https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/Candidate/${llll.c ? llll.c : ''}.jpg`}/><br/><span>{llll.lp ? llll.lp : ''}</span><br/>{llll.c ? llll.c : ''} <br/><span style={{color:"green"}}>(Won)</span></li>
                                { llll.c2 && llll.c2.length > 0 &&
                                <li><img src={`https://iansportalimages.s3.ap-south-1.amazonaws.com/others-img/Candidate/${llll.c2 ? llll.c2 : ''}.jpg`}/><br/><span>{llll.lp2 ? llll.lp2 : ''}</span><br/>{llll.c2 ? llll.c2 : ''} <br/><span style={{color:"red"}}>(Lost)</span></li>
                                }
                            </ul>
                          </div>
                        </div>   
                          }                      
                            </>
                          )
                        })}



                    </Marquee>
                    }
              </div>

              <a href='https://ndtv.com' title="NDTV"><h2 className='ndtv-poll' >Powered by ndtv.com</h2></a>
            </div>
            
            
            }
        
            { tab && tab=='news' &&  
            <div id="News" className="tabcontent">
              {/* <h2 className="m-hed">assembly election results 2019 </h2> */}
              <div className="points_table" dangerouslySetInnerHTML={{ __html:  poolData ? poolData : '' }} ></div>
            </div>
            } 
            {/* {  (!props || !props.showType )&&          
                  <button className="view_more_election"onClick={ElectionGo}>View More election results</button>     
            }      */}
 </div>  }   
      {!electBtn && 
          <p className="show-results" onClick={EleCloseBtn}> <i className="bi bi-chevron-double-down"></i> SHOW ELECTION RESULTS <i className="bi bi-chevron-double-down"></i> </p>
      }   

       
        </div>		
      </section>
    
   
    

    </>

  )
}

export default ElectionHome
