import {NavLink, useParams} from 'react-router-dom';

const HeadingTab=(props)=>{
    return(
        <>
            {/* <div className="row justify-content-end">
                <div className="col-lg-12">
                    <div className="row justify-content-end">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <NavLink className="nav-link" to={`/english-wire/all/1`}>All News</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={`/english-wire/national/1`}>National</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={`/photo-wire/all/1`}>Photo Service</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to={`/video-wire/multimedia_videos/1`}>Video Service</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default HeadingTab