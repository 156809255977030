import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react'
import axios from 'axios'
import FileSaver from "file-saver"
import {useParams} from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import {Helmet} from "react-helmet"
import copy from "copy-to-clipboard"
import Permision from './Permision'

import { useHistory } from "react-router-dom"
import { format, parseISO } from 'date-fns'
import Clar from './Clar'

const EnglishWireDetail = () =>{
   const history = useHistory()
   const [backImage, setBackImage] = useState('')  
   const {newsId} = useParams()
   const [collectionData, setCollectionData] = useState([])
   const [login, setLogin] = useState(true)
   const [errorMsg, setErrorMsg] = useState('')
   const [latData, setLatestData] = useState({})
   const LatestData =()=>{
      axios
      .get(`https://cms.iansnews.in/api/elastic/news/list/?language=english`).then((res) => {
      const latestStories =
         res.data.results &&
         res.data.results.length > 0 &&
         res.data.results.slice(0, 8).map((item) => {
            let tgSt= item.tags.filter(it => it.name === 'Business')

         return {
            byline: item.byline,
            content: item.short_desc,
            id: item.id,
            image: item.thumbnail ? item.thumbnail :'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
            title: item.title,
            slug:'/english-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): '',
            slugType: tgSt && tgSt.length > 0 ? true : false,
            image_caption: item.image_caption,
         }})
      setLatestData(latestStories)
      })
   }
   const formatDate = (timestamp)=>{
      const date = parseISO(timestamp);
      return format(date, 'MMMM dd, yyyy h:mm a');
   }

   const copyToText = async () => {
   const tempDiv = document.createElement('div')
   tempDiv.innerHTML = collectionData.copy_content

   // Remove most HTML tags except for <p>
   let copyt = ''

   // Replace specific <p> tags with double line breaks
   //copy = copy.replace(/<p><\/p>/g, '\n\n');

   // Keep specific <p> tags at the bottom
   const specificTags = tempDiv.querySelectorAll('p')
   specificTags.forEach((tag) => {
      copyt += '\n\n' + tag.innerHTML;
   });

   // Combine title, short_desc, and modified content
   let final_copy = '';
   //if(final_copy){
      final_copy = `${collectionData.title}\n\n${collectionData.short_desc} ${copyt}`
      final_copy=final_copy.replace("<br>","\n")
      final_copy=final_copy.replace('<br>','\n')
      final_copy = final_copy.replace(/&nbsp;/g, ' ')
      final_copy=final_copy.replace('<br data-mce-bogus="1">','')
      copy(final_copy);
      handleSubmit(collectionData.title, collectionData.id)
   //}
   // Copy the final content to the clipboard
   //await navigator.clipboard.writeText(final_copy);
   }
   const downloadToText = async () => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = collectionData.copy_content;

      // Remove most HTML tags except for <p>
      let copyt = '';

      // Replace specific <p> tags with double line breaks
      //copy = copy.replace(/<p><\/p>/g, '\n\n');

      // Keep specific <p> tags at the bottom
      const specificTags = tempDiv.querySelectorAll('p');
      specificTags.forEach((tag) => {
         copyt += '\n\n' + tag.innerHTML;
      });

      // Combine title, short_desc, and modified content
      let final_copy = `${collectionData.title}\n\n${collectionData.short_desc} ${copyt}`;
      final_copy=final_copy.replace('<br>','\n')
      final_copy = final_copy.replace(/&nbsp;/g, ' ')
      final_copy=final_copy.replace('<br data-mce-bogus="1">','')
      // Copy the final content to the clipboard
      copy(final_copy);
      const filename = collectionData.id+".txt"; // Replace with the desired file name

      const blob = new Blob([final_copy], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      handleSubmit(collectionData.title, collectionData.id)
   }
   const getData = async() =>{

   const apiD =`https://cms.iansnews.in/api/elastic/news/detail/en/${newsId}/?language=english
   `
   const config = {
      headers: {
         Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      }
      }
   axios.get(apiD, config)
   .then(res =>
   {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   //let new_main_content_string = '';

      
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
         if(res.data.results[0] && res.data.results[0].byline){
            let newIi = res.data.results[0].byline;
            newIi = newIi.replace(" ", "-");
            newIi = newIi.replace(" ", "-");
            newIi = newIi.replace(" ", "-");
            newIi = newIi.replace(" ", "-");
            newIi = newIi.replace(" ", "-");
            newIi = newIi.replace(" ", "-");
      
            newIi = newIi.toLowerCase();
            imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.png';
         }

   }

   setCollectionData({
      byline: res.data.results[0].byline,
      byImg:imgByLine,
      short_desc:res.data.results[0].short_desc,
      content: '<p>'+res.data.results[0].short_desc +'</p>'+ main_content_string,
      copy_content: res.data.results[0].content,
      id: res.data.results[0].id,
      image:res.data.results[0].images_thumbnails[0].image,
      title: res.data.results[0].title,
      images_thumbnails: res.data.results[0].images_thumbnails,
      // img_slug: '/photo-wire-detail/'+res.data.results[0].images_thumbnails[0].slug,
      img_slug:'/photo-wire-detail/'+res.data.results[0].images_thumbnails[0].slug,
      
      tags: res.data.results[0].tags,
      video_preview:res.data.results[0].video_url ? res.data.results[0].video_url :'',
      dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
      slug:'/'+res.data.results[0].slug,
      image_caption: res.data.results[0].image_caption
   })
   setBackImage(`background: url(${res.data.results[0].thumbnail}) no-repeat 50% 50%`)
   const tagData = res
      && res.data 
      && res.data.results 
      && res.data.results.length > 0 
      && res.data.results[0] 
      && res.data.results[0].tags 
      && res.data.results[0].tags.length > 0 
      && res.data.results[0].tags.map((taggg,tt) => {
      return {
         id: taggg.id,
         name: taggg.name,
         slug: taggg.slug
      }
      })
   console.log("Your new array of modified objects here",collectionData)
   })
   .catch(err => {
   console.log('Google api calendar error', err) 
   if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
     // history.push('/permision');
   }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
      setErrorMsg(err.response.data.detail)
      //history.push('/permision');
   }
   }})
   }
   const handleSubmit = (title, id) => {
      //e.preventDefault();
      let formData = {
         "content_id":  id ? id : '',
         "username": localStorage && localStorage.userName ? localStorage.userName : '',
         "content_type": "English News",
         "content_title": title ? title : ''
      }
      axios.post('https://cms.iansnews.in/api/download_records/', formData)
      .then(response => {
         console.log('Success:', response.data);
         // Optionally, reset form fields

      })
      .catch(error => {
         console.error('Error:', error);
      });
   }
const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const toggleVisibility = () => {
   if (window.pageYOffset > 500) {
       setIsVisible(true)
   } else {
       setIsVisible(false)
   }
}
 useEffect(() => {
   if(!localStorage || localStorage.length == 0 || !localStorage.accessToken){
      localStorage.setItem('redirectTo', newsId ? '/'+newsId :'');
      localStorage.setItem('langee', 'english');
      history.push('/login');
  }else if(localStorage && localStorage.length > 0 && localStorage.accessToken && (!localStorage.engStatus || localStorage.engStatus=='false' || localStorage.engStatus == false)){
     // history.push('/permision');
   }
   if(localStorage && localStorage.accessToken){
      setLogin(false)
   }else{
      setLogin(true)
   }


   LatestData();
   getData();
   scrollToTop();

   window.addEventListener("scroll", toggleVisibility)
   return () => window.removeEventListener("scroll", toggleVisibility)
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content={collectionData.short_desc} />
            <meta property="og:title" content={collectionData.title} />
            <meta property="og:description" content={collectionData.short_desc} />
            <meta property="og:image" content={collectionData.image} />
            <meta property="og:url" content={'https://iansphoto.in/detail'+collectionData.slug} />
            <meta property="og:type" content="article" />
        </Helmet>
        {!errorMsg &&
         <Header show={login}/>  
        } 
         {!errorMsg &&    
         <main id="main">
            <div className="container full-news">
               <section id="about" className="about">
                     <div className="row">
                        <div className="col-lg-9">
                        <div id="print">
                           <div className="inews">
                              <h2 className="title" id="newsheading">{collectionData.title}</h2>
                           </div>
                           <div className="ians-userdetail-share ians-story-author-section">
                              <div className="ians-author-detial-left">
                                 <div className="ians-author-icons">
                                    <img height="45" width="45" title="IANS" alt="IANS" src={collectionData.byImg} />
                                 </div>
                                 <div className="ians-author-brand-detial">
                                    <ul>
                                       <li>{collectionData.byline}</li>
                                       <li>{collectionData.dateTime}</li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="share-social-icons">
                                 <ul>
                                    <li className="downloadhbtn">
                                       <a alt="Download file" href="#" title="Download file" onClick={downloadToText}>
                                          <span>Download</span>
                                          <i alt="Download file" className="bi bi-download"> </i>
                                       </a>
                                    </li>
                                    <li className="downloadhbtn">
                                       <a href="#" title="Copy Text" onClick={copyToText}>
                                       <span>Copy</span>
                                          <i className="bi bi-copy" id="copyicon"></i>
                                       </a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           
                           <div className="full-article">
                           <figure>

                           <a href={collectionData.img_slug} title={collectionData.title}>
                              	<img src={collectionData.image} alt={collectionData.title} />
                           <style>
                                    {`
                                       .full-article figure a:after {
                                          ${backImage};
                                       }
                                    `}
                                 </style>
								      </a>	
                        
						                                                   
                                 </figure>
                              <div className="full-article-details" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                           </div>
                           <ul className="list-img-col">

                              {collectionData && collectionData.images_thumbnails && collectionData.images_thumbnails.length > 1 && collectionData.images_thumbnails.slice(1, 10).map((rel, r)=>{
                                       return(
                                          <li key={r}>
                                             <figure>
                                                <span className="img-col-bottom">
                                                   <a href={'https://iansphoto.in/detail/'+rel.slug} target="_blank">
                                                      <img alt="#"  src={rel.image ? rel.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg'}/>
                                                   </a>
                                                </span>
                                             </figure>
                                          </li>
                                       )
                                    })}	
                           </ul>
                           <ul className="tags">
                              {collectionData.tags && collectionData.tags.length > 0 && collectionData.tags.map((tgg,t)=>{
                              return(
                                 <li key={t}><NavLink to={`/english-wire/${tgg.slug}/1`} className="tag">{tgg.name}</NavLink></li>
                              )})}
                           </ul>
                        </div>
                        <Clar data={'english'} />
                        </div>
                        <div className="col-lg-3 latest-news-sec">
                           <div className="main-heading">
                                 <h2 className="heading">LATEST NEWS</h2>
                           </div>
                           <ul className="list-unstyright">
                              {latData && latData.length > 0 && latData.map((lttt, tl)=>{
                                 return(
                                    <>
                                       <li key={tl}>
                                          <figure>
                                             <figcaption className="tabtextarea-unstyright">
                                                <NavLink title={lttt.title} alt="#" to={lttt.slug}>
                                                   <h3 className="title">{lttt.title}</h3>
                                                </NavLink>
                                             </figcaption>
                                             <div className="img-area-unstyright">
                                                <NavLink to={lttt.slug} title={lttt.title}>
                                                   <img src={lttt.image} title={lttt.title} alt="#" />
                                                </NavLink>
                                             </div>
                                          </figure>
                                       </li>                                 
                                    </>
                                 )
                              })}

                           </ul>
                        </div>
                     </div>
                    
               </section>
            </div>
         </main>        
      }      
         {errorMsg &&
         <Permision />
         } 
      { !errorMsg &&
        <Footer/>  
        }      
        </>
    )
}

export default EnglishWireDetail