import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const TopVideoCrow = (props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const swiperRef = useRef(null);

  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxIndex(0);
  };

  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  };

  return (
    <section id="testimonials" className="testimonials" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {/* <div className="main-heading">
        <h2 className="heading">{props && props.title ? props.title : ''}</h2>
      </div> */}
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          668: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }}
      >
        <div className="clients-slider swiper four-col-category-list" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper four-col-category-list">
            {props && props.data && props.data.length > 0 && props.data.map((deep, d) => {
              return (
                <SwiperSlide key={d}>
                  <div className="testimonial-item four-col-category-list">
                  <NavLink to={deep.slug}>
                    <figure>
                   
                      <div className="img-area-four-col-category-list">
                      
                          <img src={deep.image} title={deep.title} alt="#" />
                       
                        {props && props.vTag &&
                          <div className="img-box-wrap">
                            <i className="bi bi-play" aria-hidden="true"></i>
                          </div>
                        }
                        {props && props.pTag &&
                          <div className="img-box-wrap">
                            <i className="bi bi-camera" aria-hidden="true"></i>
                          </div>
                        }
                      </div>
                      <figcaption className="textarea-four-col-category-list">
                        
                          <h3 className="title">{deep.title}</h3>
                        
                        <div className="cat-date-wrap">
                          <span className="cd-date-l">{deep.dateTime}</span>
                        </div>
                      </figcaption>
                    </figure>
                    </NavLink>
                  </div>
                </SwiperSlide>
              )
            })}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </section>
  )
}

export default TopVideoCrow;
