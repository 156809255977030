import React,{useState, useEffect} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import AdComponent from './AdComponent';



const Category = ()=>{
const [cateData, setCateData] = useState({})
const {categoryId, offset} = useParams();
const [currentPage, setCurrentPage] = useState(1);
const [pageList, setPageList] = useState([1,2,3]);
const [lastPage, setLastPage] = useState(1);
const [lt, setLt] = useState(5);
const [requestFlag, setRequestFlag] = useState(true);
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp);
    return format(date, 'MMMM dd, yyyy h:mm a');
}

//alert(categoryId);
const fetchData = async (page) => {
    page = page ? page: 1;
const getApii = `
https://cms.iansnews.in/api/elastic/imagenews/list/?tags=${categoryId}&page=${page}`;
   axios.get(getApii)
     .then(res => {

       const capitalizedCatText = categoryId ? categoryId.toUpperCase(): '';
if(lastPage == 1){
setLastPage(res.data.numpages);

}
const getCatData =
res.data.results &&
res.data.results.length > 0 &&
res.data.results.map((item) => {
return {
    byline: item.byline,
    content: item.short_desc,
    id: item.id,
    image: item.image,
    image_count:item.image_count,
    dateTime: item.created_at ? formatDate(item.created_at): '',
    title: item.title,
    tags: item.tags,
    slug:'/event/'+item.slug,
    video_preview:item.video_url? item.video_url :'',
    image_caption: item.image_caption,
}})
        setCateData(getCatData);
        setLt(res.data.numpages);
        setRequestFlag(false);
        
   })
   .catch(err => {
     // console.log('Google api calendar error', err)
    })

}
const history = useHistory();
const handleNextPage = () => {
if(lastPage > currentPage){
  setCurrentPage(currentPage + 1);
   }
}
const [isVisible, setIsVisible] = useState(false);

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const handlePrevPage = () => {
    if(currentPage > 1){
    
      setCurrentPage(currentPage - 1);
      let newArry = pageList;
    
      let newAddArray = pageList[pageList.length-1];
      let newRemoveArray = pageList[0]-1;
        newArry.unshift(newRemoveArray);
      newArry.pop(newAddArray);
    setPageList(newArry);
    }
    }
  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
useEffect(() => {
    setCurrentPage(offset)
  fetchData(offset)
  //alert(offset)
  scrollToTop();


  window.addEventListener("scroll", toggleVisibility);

  return () => window.removeEventListener("scroll", toggleVisibility);
 
//  .catch(err => { console.log('Google api calendar error', err) })
}, [categoryId, currentPage, offset])
    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <title>Indo-Asian News Service-{categoryId ? categoryId.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={''} />
            <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
        </Helmet>
         <Header show={false}/>
         <section className="gen-section-padding-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading">
                            <h2>{categoryId ? categoryId.toUpperCase(): ''}</h2>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            {cateData && cateData.length > 0 && cateData.map((caty, c)=>{
                                return(
                                    <div className="col-xl-3 col-lg-4 col-md-6" key={c}>
                                    <div className="movie type-movie status-publish has-post-thumbnail hentry movie_genre-action movie_genre-adventure movie_genre-drama">
                                        <div className="gen-carousel-movies-style-2 movie-grid style-2">
                                            <div className='thumb album-thumb'>
                                                <NavLink to={caty.slug}>
                                                <div className='thumb-container'>
                                                    <div className='images-container'>
                                                        <img className='thumb-image' src={caty.image} alt={caty.image_caption}/>
                                                    </div>
                                                    <div className='photo-count'>
                                                        <div className='content'>
                                                            <div className='number'>{caty.image_count}</div>
                                                            <div className='label'>PHOTOS</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </NavLink>    
                                                <div className='title'>{caty.title}</div>
                                                <div className="label text-white">{caty.dateTime}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="gen-pagination">
                        <nav className="pagination-outer" aria-label="Page navigation">
                <ul className="pagination">
    {currentPage && currentPage > 1 &&
            <li className="page-item" onClick={handlePrevPage}>

                <NavLink to={`/category/${categoryId}/${currentPage-1}`} className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                </NavLink>
                            
            </li>
            }
                {currentPage > 1 && <li onClick={()=>{
                    setCurrentPage(currentPage-1)
                }}><NavLink to={`/category/${categoryId}/${parseInt(currentPage)-1}`} className="page-link" >{parseInt(currentPage)-1}</NavLink></li>}
                <li className='page-item active' onClick={()=>{
                    setCurrentPage(currentPage)
                }}><a className="page-link" href="#">{currentPage}</a></li>
                { <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+1)
                }}><NavLink to={`/category/${categoryId}/${parseInt(currentPage)+1}`} className="page-link" >{parseInt(currentPage)+1}</NavLink></li>}
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+2)
                }}><NavLink to={`/category/${categoryId}/${parseInt(currentPage)+2}`} className="page-link">{parseInt(currentPage)+2}</NavLink></li>
                <li className="page-item" onClick={handleNextPage}>
                <NavLink to={`/category/${categoryId}/${parseInt(currentPage) + 1}`} className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
                </NavLink>
                </li>					 
                
                </ul>               
            </nav>                           
                        </div>
                    </div>
                </div>
            </div>
         </section>
         <Footer/>  
        </>
    )
}
export default Category