import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import FileSaver from "file-saver";
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { format, parseISO } from 'date-fns';

const Permision = () =>{
   const history = useHistory();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = (url) => {
       setVideoUrl(url);
       setShow(true)
   }

   const [videoUrl, setVideoUrl] = useState('https://iansportalimages.s3.amazonaws.com/videos_watermark/28-09-2023-11.mp4');
   const videoStartPlay = (url)=>{
       setVideoUrl(url);
   }   
const {newsId} = useParams();
const [textToCopy, setTextToCopy] = useState('')
const [isLoading, setIsLoading] = useState(false);
const [collectionData, setCollectionData] = useState([]);
const [tgData, setTagsData] = useState([]);
const [login, setLogin] = useState(true)
const [errorMsg, setErrorMsg] = useState('');
const [latData, setLatestData] = useState({});
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/imagenews/list/?top_news=true`
   )
   .then((res) => {
   const latestStories =
       res.data.results &&
       res.data.results.length > 0 &&
       res.data.results.slice(0, 8).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           byline: item.byline,
           content: item.short_desc,
           id: item.id,
           image: item.image,
           title: item.title,
           slug:'/event/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at): '',
           slugType: tgSt && tgSt.length > 0 ? true : false,
           image_caption: item.image_caption,
       };
       });
   setLatestData(latestStories);
   });
}
const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a');
}

  const imageDownload=(url)=>{
   setIsLoading(true);
            let modifiedUrl = url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          fetch(updateUrl)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
         
  }

  const getData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/image/detail/${newsId}/?language=english
   `
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      },
    };
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.results[0].content;
   let iansIndex =  main_content_string;
   let new_main_content_string = '';

    
   let imgByLine = '';
   if(res.data.results[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.results[0] && res.data.results[0].byline){
           let newIi = res.data.results[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
     
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }

   }
  
   setCollectionData({
   caption:res.data.results[0].caption,
   content: '<p>'+res.data.results[0].short_desc +'</p>'+ new_main_content_string,
   copy_content: res.data.results[0].content,
   id: res.data.results[0].id,
   image: res.data.results[0].thumbnail,
   title: res.data.results[0].title,
   images_thumbnails: res.data.results[0].images_thumbnails,
   url: res.data.results[0].url,
   dateTime: res.data.results[0].created_at ? formatDate(res.data.results[0].created_at): '',
   slug:'/'+res.data.results[0].slug,
   image_caption: res.data.results[0].image_caption
 })
 
 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.results[0] 
 && res.data.results[0].tags 
 && res.data.results[0].tags.length > 0 
 && res.data.results[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",collectionData)
setTagsData(tagData);
})
.catch(err => {
 console.log('Google api calendar error', err) 
 if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
   }else{
      setErrorMsg(err.response.data.detail)
   }
 }})
}
 useEffect(() => {
//    if(localStorage && localStorage.accessToken){
//       setLogin(false)
//    }else{
//       setLogin(true)
//    }
//    if(!localStorage || localStorage.length == 0 || !localStorage.redirectTo){
//       localStorage.setItem('redirectTo', newsId ? '/'+newsId :'');
//       history.push('/login');
//   }
//    LatestData();
//    getData();
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service</title>
            <meta data-meta-title="IANS IN" name="keywords" content={''} />
            <meta data-meta-title="IANS IN" name="description" content={''} />
            <meta property="og:title" content={''} />
            <meta property="og:description" content={''} />
            <meta property="og:image" content={collectionData.image} />
            <meta property="og:url" content={''} />
            <meta property="og:type" content="article" />
        </Helmet>
        
         <Header/>
         <main id="main">
            <section id="about" className="about">
               <div className="container">		
                  <div className="row">
                     <div className="col-lg-12 market-01">
                     <div className="warning-msg">
                        <i className="bi bi-exclamation-octagon-fill"></i> Your don't have permission to access this page. Please contact IANS marketing team.
                     </div>
                     <div className="col-lg-12 mb-5 dbox-a ">
                        <div className="row">
                        <div className="col-md-4">
                           <div className="dbox w-100 text-center">
                           <div className="icon d-flex align-items-center justify-content-center">
                              <span className="bi bi-geo-alt-fill"></span>
                           </div>
                           <div className="text">
                              <p>
                              <span>Address:</span> IANS India Private Limited, A-6, Ground Floor, Block A, Sector-16, Noida, Uttar Pradesh - 201301
                              </p>
                           </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="dbox w-100 text-center">
                           <div className="icon d-flex align-items-center justify-content-center">
                              <span className="bi bi-phone-fill"></span>
                           </div>
                           <div className="text">
                              <p>
                              <span>Phone:</span>
                              <a href="tel://+91-120-4822400">+91-120-4822400,</a>
                              <a href="tel://+91-120-4822415">4822415,</a>
                              <a href="tel://+91-120-4822416">4822416</a>
                              </p>
                           </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="dbox w-100 text-center">
                           <div className="icon d-flex align-items-center justify-content-center">
                              <span className="bi bi-cursor-fill"></span>
                           </div>
                           <div className="text">
                              <p>
                              <span>Email:</span>
                              <a href="mailto: dakul.s@ians.in">dakul.s@ians.in,</a>
                              <a href="mailto: marketing@ians.in">marketing@ians.in</a>
                              </p>
                           </div>
                           </div>
                        </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
                
         <Footer/>       
        </>
    )
}

export default Permision