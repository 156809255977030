import React, { useEffect } from 'react';
import AdSense from 'react-adsense';

const AdComponent = () => {
  useEffect(() => {
    // Load Google AdSense script
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className='ads-feed'>
      {/* Insert your AdSense code here */}
      <AdSense.Google
        client="ca-pub-4858334344778551"
        slot="9739337803"
        style={{ display: 'block' }}
        format="fluid"
        layoutKey="-em-29+ls+4-108"
      />
    </div>
  );
};

export default AdComponent;
