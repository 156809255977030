import {NavLink} from 'react-router-dom';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const HomeSlider=(props)=>{
    return(
        <>
  {/* <section id="hero">
    <div className="hero-container" data-aos="fade-up">
		<div className="bracking-main">
		  <div className="container">
			<div className="bracking-new-mob">
			  <div className="braking-inner">
				<div className="bracking-left">
				  <h2>Breaking News</h2>
				</div>
				<div className="brack-text-rigt">
				  <a title="#" href="#">Farmers' protest: Huge security build-up continue on inter-state borders</a>
				</div>
			  </div>
			</div>
		  </div>
		</div>
    </div>
  </section>   */}
    
        </>
    )
}
export default HomeSlider;