import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Marquee from "react-fast-marquee";

const Breaking = () => {
  const [breaking, setBreakData] = useState([]);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const getBreakData = () => {
      axios
        .get(`https://cms.iansnews.in/api/elastic/breakingnews/list/?is_active=true`)
        .then((res) => {
          const breakk =
            res.data &&
            res.data.length > 0 &&
            res.data.map((item) => {
              return {
                created_at: item.created_at,
                headline: item.headline,
                is_active: item.is_active,
                updated_at: item.updated_at
              };
            });
          setBreakData(breakk);
        });
    };

    getBreakData();
  }, []);

  const handleMouseOver = () => {
    setIsPaused(true);
  };

  const handleMouseOut = () => {
    setIsPaused(false);
  };

  return (

    <>
    {breaking && breaking.length > 0 && 
        <section>
        <div className="hero-container" data-aos="fade-rih">
          <div className="bracking-main">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center breaking-news bg-white">
                  <div className="d-flex breaking-news-hed">
                    <span>BREAKING NEWS</span>
                  </div>
                  <Marquee
                    className="news-scroll"
                    pauseOnHover={true}
                    speed={50}
                    loop={100}
                  >
                    {breaking.length > 0 && breaking.map((bre, b) => (
                      <a href="" key={b} title={bre.headline}>
                        {bre.headline}
                      </a>
                    ))}
                  </Marquee>
                </div>
            </div>
          </div>
        </div>
      </section>
    
    }    
    </>
  )
}

export default Breaking;
