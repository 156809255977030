import { Helmet } from "react-helmet"
import Footer from "./Footer"
import Header from "./Header"
import React, { useState, useEffect, useRef } from 'react';
//import './SlideStories.css'; // Import your CSS file

import axios from 'axios';
import { format, parseISO } from 'date-fns';
import {NavLink, useParams, useHistory} from 'react-router-dom';

const Stories = ()=>{
  const [active, setActive] = useState(0);
  const timeoutRef = useRef(null);
  const [slides, setphotoData] = useState([])

  const getAllPhotoData =()=>{


         let apiD = `https://cms.iansnews.in/api/elastic/news/list/?language=english&topNews_category=7522`;
    
    const config = {
        headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
        },
    }
    axios.get(apiD)
    .then((res) => {
    const topStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 5).map((item) => {
            // let tgSt= item.tags.filter(it => it.name === 'Business')
        return {
            caption: item.caption,
            id: item.id,
            image_name: item.image_name,
            keywords: item.keywords,
            slug:'/detail/'+ item.slug,
            image: item.image,
            description:item.short_desc,
            title: item.title,
            url: item.url,
            
        }});

        setphotoData(topStories);
    });
}

  useEffect(() => {
    const autoSlide = () => {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => next(), 5000)
    }
    getAllPhotoData()
    autoSlide()

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [active])
  const prev = () => {
    setActive(active === 0 ? slides.length - 1 : active - 1)
  }

  const next = () => {
    setActive(active === slides.length - 1 ? 0 : active + 1)
  }

  const jumpToSlide = (index) => {
    setActive(index)
  }
    return(
        <>
        <Helmet>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta http-equiv='X-UA-Compatible' content='IE=edge'/>
            <meta name='author' content=''/>
            <link rel="icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" /><meta name="theme-color" content=''/>
            <link rel="canonical" href="https://www.iansphoto.in"/>
            <title>Indo-Asian News Service</title>
            <link rel="shortcut icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="msvalidate.01" content="" />
            <link  rel="alternate" type="application/rss+xml" title="iansphoto" href="https://www.iansphoto.in/rss-feed.xml"/>
            <meta name="baidu-site-verification" content="1VrDq6uj7E" />
            <meta name="google-site-verification" content="" />
            <meta name='dmca-site-verification' content='' />
            <meta property="og:title" content="Indo-Asian News Service" />
            <meta property="og:description" content="Indo-Asian News Service" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://iansphoto.in/" />
            <meta property="og:image" content="https://iansphoto.in/images/Logo.png" />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@IANS"/>
            <meta name="twitter:creator" content="@IANS"/>
            <meta name="twitter:title" content="Indo-Asian News Service"/>
            <meta name="twitter:description" content="Indo-Asian News Service"/>
            <meta name="twitter:image" content="https://iansphoto.in/images/Logo.png"/>            
        </Helmet>
        <div className="slide">
            <div className="slide-items">
                {slides.map((slide, index) => (
                <div key={index} className={`items-img ${index === active ? 'active' : ''}`}>
                    <img src={slide.image} alt={`Img ${index + 1}`} />
                    <div className="post-content">
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                    </div>
                </div>
                ))}
            </div>
            <nav className="slide-nav">
                <div className="slide-thumb">
                {slides.map((_, index) => (
                    <span
                    key={index}
                    className={`thumb-item ${index === active ? 'active' : ''}`}
                    onClick={() => jumpToSlide(index)}
                    />
                ))}
                </div>
                <button className="slide-prev" onClick={prev}>
                Prev
                </button>
                <button className="slide-next" onClick={next}>
                Next
                </button>
            </nav>
        </div>
        </>
    )
}
export default Stories