// IndexPage.js
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

function IndexPage() {
  const {NwId} = useParams();

  const history = useHistory();
  const location = useLocation();
  const param = location.search.replace('?param=', ''); // Extract the parameter from the query string

  useEffect(() => {
    const url = 'http://localhost:3000/index.php?param=news/C-1-963213';

// Parse the URL to extract the query parameters
const urlSearchParams = new URLSearchParams(url.split('?')[1]);

// Get the value of the 'param' query parameter
const paramValue = urlSearchParams.get('param');

// Split the 'param' value to get 'C-1-963213'
const parts = paramValue.split('/');
const desiredValue = parts[1]; // 'C-1-963213'

// Print the extracted value
console.log(desiredValue);

    //alert(desiredValue)
    // Perform the redirection to the target URL
    history.push(`/detail/${desiredValue}`);
  }, [param, history]);

  // This component doesn't need any content to display

  return null;


  
}




export default IndexPage;
