import { Helmet } from "react-helmet"
import Footer from "./Footer"
import Header from "./Header"
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import {NavLink, useParams, useHistory} from 'react-router-dom';

const AllPhotos = ()=>{
    const [photoData, setphotoData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const {offset} = useParams()
    const [lastPage, setLastPage] = useState(1)
    const [search, setSearch] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const history = useHistory()

    const formatDate = (timestamp)=>{
		const date = parseISO(timestamp);
		return format(date, 'MMMM dd, yyyy h:mm a');
	 }
     const submitSearch=(e)=>{
       setCurrentPage(1)
        e.preventDefault()
        getAllPhotoData(currentPage, search,'2')
                history.push(`/all-photos/1`)

     }
     const inputSData=(event)=>{
        event.preventDefault()
        //localStorage.removeItem(search);
        setSearch(event.target.value);
      }
    const handleNextPage = () => {
    if(lastPage > currentPage){
        setCurrentPage(currentPage + 1);
        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        }
    const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1)}
    }
    const handleSetPage = (page) => {
        if(page > 0){
            setCurrentPage(page)
    }}
   
    const getAllPhotoData =(page, type,ty)=>{

        page = page ? page: 1;

        let apiD ='';
        if(ty == '2'){
             apiD = `https://cms.iansnews.in/api/elastic/single-image/?query=${search}&page=1&free_photo=false`;
        }else{
             apiD = `https://cms.iansnews.in/api/elastic/single-image/?query=${search}&page=${page}&free_photo=false`;
        }
        const config = {
            headers: {
            Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
            },
        }
        axios.get(apiD)
        .then((res) => {
        const topStories =
            res.data.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => {
                // let tgSt= item.tags.filter(it => it.name === 'Business')
            return {
                caption: item.caption,
                free_photo: false,
                id: item.id,
                image_name: item.image_name,
                keywords: item.keywords,
                slug:'/detail/'+ item.slug,
                dateTime: item.created_at ? formatDate(item.created_at): '',
                image: item.thumbnail,
                title: item.title,
                url: item.url,
                
            }});
            if(lastPage == 1){
                setLastPage(res.data.numpages);
                }
            setphotoData(topStories);
        });
    }
    useEffect(() => {
        setCurrentPage(offset)
        getAllPhotoData(offset,search,'1')
        scrollToTop();
      }, [currentPage, offset])
    return(
        <>
        <Helmet>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta http-equiv='X-UA-Compatible' content='IE=edge'/>
            <meta name='author' content=''/>
            <link rel="icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" /><meta name="theme-color" content=''/>
            <link rel="canonical" href="https://www.iansphoto.in"/>
            <title>Indo-Asian News Service</title>
            <link rel="shortcut icon" href="icon-new.ico" type="image/x-icon" />
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta name="msvalidate.01" content="" />
            <link  rel="alternate" type="application/rss+xml" title="iansphoto" href="https://www.iansphoto.in/rss-feed.xml"/>
            <meta name="baidu-site-verification" content="1VrDq6uj7E" />
            <meta name="google-site-verification" content="" />
            <meta name='dmca-site-verification' content='' />
            <meta property="og:title" content="Indo-Asian News Service" />
            <meta property="og:description" content="Indo-Asian News Service" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://iansphoto.in/" />
            <meta property="og:image" content="https://iansphoto.in/images/Logo.png" />
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@IANS"/>
            <meta name="twitter:creator" content="@IANS"/>
            <meta name="twitter:title" content="Indo-Asian News Service"/>
            <meta name="twitter:description" content="Indo-Asian News Service"/>
            <meta name="twitter:image" content="https://iansphoto.in/images/Logo.png"/>            
        </Helmet>
        <Header />
            <section className="position-relative gen-section-padding-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="pm-inner">
                                    <div className="gen-more-like">
                                    <div className="section-heading">
                                        <h2>{'All Photos'}</h2>
                                    </div>
                                        <form onSubmit={submitSearch}>
                                            <div className="search-form-allphoto input-group">
                                                <input type="text" className="form-control" placeholder="Search photo" onChange={inputSData} name="search" value={search}/>
                                                
                                                <span className="input-group-text border-0" onClick={submitSearch}><i className="fa fa-search" aria-hidden="true"></i></span>
                                            </div>
                                        </form>
                                        <div className="row post-loadmore-wrapper allphoto-sec">
                                        {photoData && photoData.length > 0 && photoData.map((photo, p)=>{
                                            return(
                                                <div className="col-xl-3 col-lg-4 col-md-6 mb-3 img-col4" key={p}>
                                                <NavLink to={photo.slug} title={photo.caption}>
                                                    <div className="thumb w-100  album-thumb p-4">
                                                        <img className="thumb-image w-100" src={photo.image} />
                                                        <div className="text-l-gray des-title mt-3">{photo.caption}</div>
                                                        <div className="label text-white label-photo">{photo.dateTime}</div>
                                                    </div>
                                                </NavLink>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                    <div className="gen-pagination">
                                    <nav className="pagination-outer" aria-label="Page navigation">
                <ul className="pagination">
                <li className="page-item" onClick={handleNextPage}>
                <NavLink to={`/all-photos/${parseInt(1)}`} className="page-link" aria-label="Next">
                First
                </NavLink>
                </li>  
    {currentPage && currentPage > 1 &&
    
            <li className="page-item" onClick={handlePrevPage}>

                <NavLink to={`/all-photos/${currentPage-1}`} className="page-link" aria-label="Previous">
                    <span aria-hidden="true">«</span>
                </NavLink>
                            
            </li>
            }
                {currentPage > 1 && <li onClick={()=>{
                    setCurrentPage(currentPage-1)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)-1}`} className="page-link" >{parseInt(currentPage)-1}</NavLink></li>}
                <li className='page-item active' onClick={()=>{
                    setCurrentPage(currentPage)
                }}><a className="page-link" href="#">{currentPage}</a></li>
                {lastPage > parseInt(currentPage)  &&
                 <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+1)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+1}`} className="page-link" >{parseInt(currentPage)+1}</NavLink></li>}
{lastPage > parseInt(currentPage) + 1  &&
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+2)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+2}`} className="page-link">{parseInt(currentPage)+2}</NavLink></li>}
{lastPage > parseInt(currentPage) + 2  &&
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+3)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+3}`} className="page-link">{parseInt(currentPage)+3}</NavLink></li> } 
{ lastPage > parseInt(currentPage) + 3  &&
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+4)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+4}`} className="page-link">{parseInt(currentPage)+4}</NavLink></li> } 
{lastPage > parseInt(currentPage) + 4  &&
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+5)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+5}`} className="page-link">{parseInt(currentPage)+5}</NavLink></li> } 
{lastPage > parseInt(currentPage) + 5  &&
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+6)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+6}`} className="page-link">{parseInt(currentPage)+6}</NavLink></li> }                                                   {
                    lastPage > parseInt(currentPage) + 6  &&
                    <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+7)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+7}`} className="page-link">{parseInt(currentPage)+7}</NavLink></li>} 
{lastPage > parseInt(currentPage) + 7  && 
               <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+8)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+8}`} className="page-link">{parseInt(currentPage)+8}</NavLink></li> }
{lastPage > parseInt(currentPage) + 8  &&                  
                <li className='page-item' onClick={()=>{
                    setCurrentPage(currentPage+9)
                }}><NavLink to={`/all-photos/${parseInt(currentPage)+9}`} className="page-link">{parseInt(currentPage)+9}</NavLink></li>                                
}
{
              <li className="page-item" onClick={handleNextPage}>
                <NavLink to={`/all-photos/${parseInt(currentPage) + 1}`} className="page-link" aria-label="Next">
                <span aria-hidden="true">»</span>
                </NavLink>
                </li>	}				 
                <li className="page-item" onClick={handleNextPage}>
                <NavLink to={`/all-photos/${parseInt(lastPage)}`} className="page-link" aria-label="Next">
                Last
                </NavLink>
                </li>                
                </ul>               
            </nav> 
                                    </div>
                                </div>
                        </div>
                    </div>
            </section>
        <Footer />
        </>
    )
}
export default AllPhotos